import { lazy } from 'react';
export default class BBMPythonDescriptor  {

static getDescription() {
    return {
      name: 'BBPython',
      component: lazy(() =>  import('./BBPython')),
      label: 'BB Python in the browser(Experimental)',
      type:"experimental",
      variables: [
       

        {
          name: 'pythoncode',
          type: 'text',
          label: 'python code to execute between {}'
        },
       
        
      ],
    }
  }
}
